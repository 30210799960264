// body
//     background-color: #F0F0F0

.about {
    text-align: center;

    .header {
        text-align: left;
        max-width: 950px;
        margin: auto;

        .logo {
            margin: 10px; }

        hr {
            margin-top: 0px;
            border: none;
            height: 1px;
            background-color: black; } }

    .welcome {
        text-align: left;
        max-width: 950px;
        margin: auto;
        overflow: hidden;
        margin-bottom: 30px;

        .text {
            width: 600px;
            max-width: 100%;
            display: inline-block;
            vertical-align: top;

            .heading {
                font-size: 60px;
                font-family: Tiempos;
                margin-top: 30px;
                margin-bottom: 30px;
                font-weight: 300; }

            .subHeading {
                font-weight: 500;
                font-family: Universal;
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 15px; }

            .content {
                font-size: 15px;
                font-weight: 500;
                line-height: 21px;
                margin-bottom: 15px; } }

        .image {
            margin-left: -50px;
            display: inline-block;
            width: 400px;
            vertical-align: bottom;

            .rayImg {
                width: 400px; } }

        hr {
            margin-top: -6px;
            border: none;
            height: 1px;
            background-color: #CCCCCC; } }

    .columns {
        text-align: left;
        max-width: 950px;
        margin: auto;
        overflow: hidden;
        margin-bottom: 30px;

        .column1, .columnL {
            width: 300px;
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
            font-family: Universal;
            font-size: 24px;
            line-height: 33px;
            margin-bottom: 15px; }

        .columnL {
            vertical-align: bottom; }

        .columnAlt {
            display: none; }

        .column2 {
            width: 650px;
            display: inline-block;
            vertical-align: top;

            .subHeading {
                font-weight: 500;
                font-family: Universal;
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 15px; }

            .content {
                font-size: 15px;
                font-weight: 500;
                line-height: 21px;
                margin-bottom: 15px; }

            li {
                text-align: left;
                background-image: url("./img/icon-tick-star.svg");
                background-repeat: no-repeat;
                padding-top: 6px;
                min-height: 30px;
                padding-left: 42px; }

            ul {
                list-style: none;
                padding-left: 0px;
                padding-right: 20px; } }

        hr {
            margin-top: 40px;
            border: none;
            height: 1px;
            background-color: #CCCCCC; }

        .signature {
            margin-top: 15px;
            margin-bottom: 20px; } }

    .partners {
        background-color: white;
        max-width: 950px;
        margin: auto;
        overflow: hidden;
        margin-bottom: 40px;
        padding-top: 20px;
        padding-bottom: 20px;
        line-height: 24px;

        .carousel-item {
            transition: none;
            transition-timing-function: linear; }

        .partner {
            width: 25%;
            display: inline-block;
            padding-top: 9px;
            padding-bottom: 6px;

            .logo {

                .partnerLogo {
                    width: 200px;
                    filter: grayscale(100%); } }

            .description {
                font-size: 15px;
                color: #999999;
                line-height: 21px; } } }

    .footer {
        background-color: black;
        color: white;
        padding-top: 40px;
        padding-bottom: 40px;

        .content {
            font-weight: 500;
            font-size: 18px;
            max-width: 950px;
            margin: auto;
            overflow: hidden;
            padding-left: 20px;
            padding-right: 20px; }

        .contentSmall {
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            margin-left: 20px;
            margin-right: 20px; }

        .socialIcon {
            height: 50px;
            margin: 15px;
            margin-top: 10px;
            margin-bottom: 10px; } }

    @media (max-width: 999px) {
        .welcome {
            max-width: 100%;
            text-align: center;

            .text {
                padding-left: 20px;
                padding-right: 20px;
                max-width: calc(100% - 40px);
                text-align: left;

                .heading {
                    font-size: 42px;
                    line-height: 48px; }

                .subHeading {
                    font-size: 21px;
                    line-height: 30px; } }

            .image {
                margin-left: 0px;
                max-width: 100%;
                width: 600px;
                text-align: right;

                .rayImg {
                    max-width: 100%; } } }

        .columns {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;
            max-width: calc(100% - 40px);

            .columnL {
                display: none; }

            .column1 {
                width: 600px;
                text-align: left;
                max-width: 100%;
                margin: auto;
                font-size: 30px;
                line-height: 33px;
                font-family: Tiempos;
                margin-bottom: 20px; }

            .column2 {
                text-align: left;
                max-width: 100%;
                width: 600px;

                .subHeading {
                    font-size: 21px;
                    line-height: 30px; } }

            .columnAlt {
                display: block; } } } }
