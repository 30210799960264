.adverse_credit_container {
    background-color: $primary;
    color: $secondary; }

.adverse-credit {
    width: 1120px;
    max-width: calc(100% - 30px);
    display: inline-block;
    padding: 0px 15px;

    .primary, .secondary {
        border-radius: 24px;
        font-size: 20px;
        background-color: $quarternary;
        color: $secondary;
        margin-bottom: 10px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        letter-spacing: 1px;
        cursor: pointer;
        padding: 12px;
        padding-left: 60px;
        padding-right: 60px;
        text-transform: uppercase;
        margin-bottom: 40px;

        &:hover {
            background-color: $quarternary-alt; } }

    .secondary {
        background-color: $secondary;
        color: $quarternary;
        border: 1px solid $quarternary;

        &:hover {
            background-color: $secondary-alt; } }

    .white {
        background-color: $secondary;
        margin: 0px -100%;
        padding: 10px 0px;

        &:nth-child(n + 2) {
            margin: 30px -20px;
            padding-bottom: 30px; }

        .white_content {
            display: inline-block;
            width: calc(1100px - 30px);
            max-width: calc(100vw - 30px);
            color: $primary;
            font-family: $font2; } }

    .logo {
        margin: 10px 0px; }

    .heading {
        font-family: $font1;
        color: $quinary;
        line-height: 36px;
        font-weight: 300;
        margin-top: 28px;
        margin-bottom: 14px;
        font-size: 40px;
        line-height: 40px;
        font-weight: 600;
        text-align: left; }

    .sub_heading {
        font-size: 30px;
        font-family: $font2;
        margin-bottom: 40px;
        font-weight: 400;
        line-height: 1.4;
        text-align: left; }

    .additional_question {
        font-size: 24px;
        line-height: 27px;
        font-family: $font2;
        color: $secondary;
        margin-top: 20px;

        select {
            width: 300px;
            padding: 5px;
            font-size: 18px;
            margin-top: 5px;
            border: 1px solid $secondary;
            outline: none; } }

    .high_level {
        font-size: 24px;
        line-height: 27px;
        font-family: $font2;
        color: $secondary;

        .primary, .secondary {
            padding: 10px;
            width: 150px;
            margin-bottom: 10px; } }

    .content {
        font-family: $font2;
        font-size: 15px;
        line-height: 21px;
        font-weight: 400;
        margin: 20px 0px; }

    button {
        background-color: $secondary;
        color: $primary;
        border-radius: 20px;
        padding: 5px 10px;
        border: 1px solid $secondary;
        margin: 5px;
        font-family: $font2;
        padding: 5px 2rem;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.428571429;

        &.active {
            background-color: $quarternary;
            color: $secondary;
            border: 1px solid $secondary; } }

    .lender_logo {
        width: 100px;
        border-radius: 50%;
        background-color: white;
        margin: 10px;
        display: inline-block;
        // box-shadow: 0px 4px 10px 0px #8a8a8a
        cursor: pointer; }

    .archive {
        background-color: rgba($secondary, 0.2);
        margin: 15px -20px;
        padding: 10px 10px;
        margin-top: -5px; }

    .lender_section {
        text-align: left;
        display: inline-block;
        vertical-align: top;
        position: relative;
        border: 1px solid $tertiary;
        padding: 0px 10px 0px 0px;
        margin: 5px;
        border-radius: 50px;
        // box-shadow: 0px 3px 8px 0px #dbdbdb
        width: 300px;

        .sub_lender_logo {
            vertical-align: middle;
            display: inline-block;
            margin-bottom: -5px;

            .lender_logo {
                margin: 0px;
                box-shadow: 0px 0px 0px 0px transparent; } }

        .sub_lender {
            vertical-align: middle;
            display: inline-block;
            padding-left: 10px;
            text-align: left;
            font-family: Universal;

            .sub_lender_accordion {
                font-size: 12px;
                color: $tertiary;
                display: inline-block;
                border-bottom: 1px solid $tertiary; } }

        .lender_reasons {
            font-size: 12px;
            text-align: left;
            padding: 15px;
            color: #838383; }

        .lenderInfo {
            .accordion_button {
                color: $tertiary;
                margin-left: 25px;
                border-bottom: 1px solid $tertiary; } } } }
