$background: #141432;
$heading: #ffed00;
$content: white;
$back: white;

$radio_background: white;
$radio_text: #141432;

$input_background: white;
$input_text: #141432;

$button_background: #5e00fa;
$button_text: white;

$heading_font: Lato, sans-serif;
$content_font: Lato, sans-serif;

$disabled: #b4b4b4;
$greyed_out: #4e4e4e;

$progress: #ffed00;
$progress_greyed: #5e00fa;

$modal_page: #141432;
$modal_background: white;
$modal_heading: #141432;
$modal_content: #141432;

$sub_color: white;
$info_color: white;
$active_color: #00dfbc;

$collection_row1: white;
$collection_row2: #00dfbc;
$collection_border: #00dfbc;
$collection_add: white;

$width: 1050px;

.creditladder {
    width: 100vw;
    height: 100vh;
    background-color: $background;

    .adverse_flow {
        background-color: $background;
        box-sizing: border-box;
        width: $width;
        display: inline-block;
        max-width: 100%;
        text-align: left;

        @import "./_modal";
        @import "./_navbar";
        @import "./_radio";
        @import "./_input";
        @import "./_selection";
        @import "./_collection";

        .heading {
            font-size: 30px;
            font-weight: 500; }

        .content {
            font-size: 24px;
            font-weight: 500;
            line-height: 28px; }

        .radio {
            font-weight: 500; }

        .navbar {
            .back {
                font-weight: 500; } }

        .modal {
            .modalContent {
                .content {
                    font-size: 15px; } } }

        .info {
            font-size: 18px; }

        .input_contain {
            .capBtn {
                border-radius: 100px;
                font-weight: 500;
                padding: 12px 60px;
                border: 1px solid $content; } }

        .radio.small {
            border-radius: 100px;
            font-weight: 500;
            padding: 12px 60px!important;
            border: 1px solid $content;
            width: 263px;
            text-align: center; } } }
