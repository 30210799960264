button {
    font-family: $content_font;
    margin: 20px 0px;
    padding: 15px 21px;
    font-size: 24px;
    line-height: 30px;
    border-radius: 30px;
    font-weight: 300;
    text-transform: uppercase;
    border: 1px solid $radio_text;
    outline: none;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    overflow: hidden; }

.radio {
    padding-left: 60px;
    background-color: $radio_background;
    color: $radio_text;
    margin: 0;
    border-radius: 0;
    text-transform: unset;
    width: calc(100% - 30px);
    margin: -1px 15px 0px 15px;
    cursor: pointer;
    text-align: left;
    font-size: 21px;
    position: relative;

    .radio_circle {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba($radio_text, 0.4);
        display: inline-block;
        position: absolute;
        transform: translate(0px, -50%);
        left: 20px;
        top: 50%;

        &.active {
            background-color: $active_color; }

        .tick {
            position: absolute;
            transform: translate(0px, -50%);
            top: calc(50% + 1px);
            left: 5px; } }

    &.small {
        background-color: $button_background;
        color: $button_text;
        width: 185px; } }

.sub_button {
    margin-top: 0px;
    border-bottom: 1px solid $sub_color;
    color: $sub_color;
    display: inline-block;
    cursor: pointer; }
