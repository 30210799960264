.listing-flow {
    background-color: #F0F0F0;

    .iddLink {
        font-weight: 700; }

    .iddTable {
        border-collapse: collapse;

        tr {
            background-color: #f0f0f0;
            border: none;

            td {
                padding: 10px;
                vertical-align: top; } } }

    .iddBox {
        text-align: center;
        border: 1px solid black;
        width: 50px;
        height: 30px;
        vertical-align: middle;

        .checkMark {
            vertical-align: middle;
            display: inline-block;
            height: 21px;
            margin-top: 4px; } } }
