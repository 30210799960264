.navbar {
    text-align: center;
    position: relative;

    .back {
        position: absolute;
        top: 0;
        margin: 20px 0px 0px 0px;
        margin-left: 0;
        margin-top: 9px;
        padding-top: 21px;
        padding-bottom: 18px;
        font-family: $content_font;
        font-size: 15px;
        border: none;
        outline: none;
        color: $back;
        text-transform: initial;
        left: 0;
        background-color: transparent;

        .backArrow, .backText {
            display: inline-block;
            vertical-align: middle; }

        .backText {
            margin-left: 10px; } }

    .progress_bar {
        width: calc(100% - 30px);
        overflow: hidden;
        display: inline-block;
        background-color: $background;

        .progress {
            background-color: $progress_greyed;
            display: inline-block;
            border: 2px solid $background;
            height: 5px;
            transition: background-color 0.2s ease-in-out;

            &.active {
                background-color: $progress; } } } }
