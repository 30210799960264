.collection {
    text-align: left;

    table {
        border-top: 1px solid $collection_border;
        border-bottom: 1px solid $collection_border;
        margin: -1px 15px 0px 15px;
        width: calc(100% - 30px);

        tbody {
            background-color: "transparent";

            tr {
                border: none;

                td {

                    &:nth-child(1) {
                        width: 30px;
                        font-weight: 400;
                        padding-left: 0px;
                        height: 20px;
                        padding-top: 10px;
                        font-size: 15px;
                        color: $collection_row1; }

                    &:nth-child(2) {
                        text-align: left;
                        height: 20px;
                        padding-top: 10px;
                        font-size: 15px;
                        color: $collection_row1; }

                    &:nth-child(3) {
                        text-align: right;
                        height: 20px;
                        font-size: 15px;
                        color: $collection_row1; } }

                &:nth-child(2) {
                    td {
                        &:nth-child(2) {
                            color: $collection_row2;
                            padding-bottom: 10px;
                            padding-top: 10px; }

                        &:nth-child(3) {
                            color: $collection_row2;

                            span {
                                cursor: pointer;
                                border-bottom: 1px solid $collection_row2; } } } } } } }

    .add_another {
        margin: 20px 15px 0px 15px;
        text-align: left;
        border-bottom: 1px solid $collection_add;
        display: inline-block;
        cursor: pointer;
        color: $collection_add; } }
