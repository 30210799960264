body,
html {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    font-family: Universal;
    overflow: overlay;
    overflow-x: hidden;
    height: 100%;
}

* {
    touch-action: manipulation;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
    max-width: 100%;
    /* display: inline-block; */
    position: relative;
    min-height: 100%;
}

.App,
.valuator {
    height: 100%;
    position: relative;
}

.listing-flow {
    box-sizing: border-box;
    width: 680px;
    display: inline-block;
    max-width: 100%;
}

.landing-new .listing-flow {
    width: 100%;
    display: block;
}

.landing-new .listing-flow .listing-flow {
    width: 680px;
    display: inline-block;
    max-width: 100%;
}

@font-face {
    font-family: Tiempos;
    src: url("./fonts/TiemposHeadline-Light.otf") format("opentype");
}

@font-face {
    font-family: Universal;
    src: url("./fonts/UniversalSans-v1-1-0-74-77-511112212111-500.ttf") format("truetype");
}

.listing-flow .logo {
    width: 10px 0 0 0;
    margin: 30px;
    margin-bottom: 0;
    margin-top: 21px;
}

.listing-flow .back {
    position: absolute;
    top: 0;
    margin: 20px 0px 0px 0px;
    margin-left: 0;
    margin-top: 9px;
    padding-top: 21px;
    padding-bottom: 18px;
    font-family: Universal;
    font-size: 15px;
    border: none;
    outline: none;
    color: #999;
    text-transform: initial;
}

.listing-flow .back:nth-of-type(1) {
    left: 0;
}

.listing-flow .back:nth-of-type(2) {
    right: 0;
}

.listing-flow .tick {
    position: absolute;
    right: 27px;
    top: 38%;
}

.listing-flow .backArrow,
.listing-flow .backText {
    display: inline-block;
    vertical-align: middle;
}

.listing-flow .backText {
    margin-left: 10px;
}

.listing-flow button {
    font-family: Universal;
    background-color: #F0F0F0;
    margin: 20px 0px;
    padding: 15px 21px;
    font-size: 24px;
    line-height: 30px;
    border-radius: 30px;
    font-weight: 300;
    text-transform: uppercase;
    border: 1px solid black;
    outline: none;
    cursor: pointer;
    transition: all 0.listing-flow .1s ease-in-out;
    overflow: hidden;
}

@media(min-width:600px) {
    .listing-flow button:hover {
        background-color: #AAFF00;
    }
}

.listing-flow button:active {
    background-color: #AAFF00;
}

.listing-flow .capBtn {
    letter-spacing: 2px;
    padding: 12px 42px;
}

.listing-flow .back:hover {
    background-color: transparent;
}

.listing-flow .page {
    margin-left: 20px;
    margin-right: 20px;
    z-index: 1;
}

.listing-flow .pageHeading {
    padding: 0px 15px;
    text-align: left;
    font-family: Tiempos;
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 10px;
    /* width: 240px; */
}

.listing-flow .priceHeading {
    font-size: 42px;
    font-family: Tiempos;
}

.listing-flow .pageContent {
    font-family: Universal;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 20px;
}

.listing-flow input {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
    padding: 10px;
    border: 1px solid black;
    outline: none;
    border-radius: 0;
    max-width: calc(100% - 20px);
    width: 273px;
}

.listing-flow .lastNameInput {
    margin-top: -1px;
}

.listing-flow .linkText {
    border-bottom: 1px solid black;
    width: auto;
    cursor: pointer;
}

.listing-flow .radio {
    margin: 0;
    border-radius: 0;
    text-transform: unset;
    width: calc(100% - 30px);
    max-width: calc(680px - 30px);
    margin: -1px 15px 0px 15px;
    cursor: pointer;
    text-align: left;
    font-size: 21px;
    position: relative;
}

.listing-flow .radio.selected {
    background-color: #AAFF00;
}


.listing-flow .calculator {
    width: 300px;
    display: inline-block;
    max-width: 100%;
}

.listing-flow .calc-btn {
    width: 100px;
    margin: 0;
    border-radius: 0;
    margin-top: -1px;
}

.listing-flow .calc-btn:nth-child(2),
.listing-flow .calc-btn:nth-child(5),
.listing-flow .calc-btn:nth-child(8),
.listing-flow .calc-btn:nth-child(11) {
    margin-left: -1px;
    margin-right: -1px;
}

.listing-flow .leaseInput {
    width: 207px;
    margin-bottom: 20px;
}

.listing-flow .priceInput {
    border-left: none;
    width: 236px;
    max-width: calc(100% - 63px);
}

.listing-flow .input-label {
    margin: 0;
    border-radius: 0;
    text-transform: unset;
    padding-right: 20px;
    margin-left: -2px;
    padding: 10px;
    padding-left: 5px;
    background-color: white;
    border: 1px solid black;
    border-left: none;
    font-size: 24px;
    line-height: 30px;
    display: inline-block;
    vertical-align: top;
}

.listing-flow .input-label-pound {
    border-right: none;
    border-left: 1px solid black;
    margin-left: 0;
    margin-right: -2px;
    padding-right: 5px;
    padding-left: 20px;
}

#bedroomAccordion {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

#bathroomAccordion,
#parkingAccordion,
#photoAccordion,
#dependents1Accordion,
#dependents2Accordion,
#employment1Accordion,
#employment2Accordion {
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
}

.listing-flow .xSymbol {
    text-align: right;
    font-size: 30px;
}

.listing-flow .progress-bar {
    width: calc(100% - 40px);
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 15px;
    background-color: #fff;
    height: 4px;
}

.listing-flow .progress {
    height: 5px;
    background-color: #000;
    transition: all 0.2s ease-in-out;
}

.listing-flow .encompass {
    margin: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 1px);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
}

.listing-flow .float-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #F0F0F0;
}

.listing-flow .backWhite {
    background-color: transparent;
}

.listing-flow .backTextWhite {
    color: white;
}

.listing-flow .descriptionBox {
    border: 1px solid black;
    text-align: left;
    margin-top: 15px;
    width: 100%;
    display: inline-block;
    max-width: 100%;
}

.listing-flow .link {
    border-bottom: 1px solid black;
    cursor: pointer;
    font-size: 15px;
}

#cancelLink {
    float: right;
    display: none;
}

.listing-flow .boxHead {
    border-bottom: 1px solid black;
    padding: 10px;
}

.listing-flow .boxContent {
    padding: 10px;
}

.listing-flow .paraInput {
    width: 90%;
    max-width: 500px;
    height: 250px;
    margin-top: 15px;
}

textarea {
    text-align: left;
    font-size: 15px;
    line-height: 21px;
    font-family: Universal;
    width: calc(100% - 5px);
    height: 200px;
}

.listing-flow .addressSelect {
    margin-bottom: 20px;
}

.listing-flow .drum {
    width: 300px;
}

#editor {
    display: none;
}

.listing-flow .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 23px;
    float: right;
}

/* Hide default HTML checkbox */

.listing-flow .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */

.listing-flow .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.listing-flow .slider:before {
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.listing-flow input:checked+.slider {
    background-color: #AAFF00;
}

.listing-flow input:focus+.slider {
    box-shadow: 0 0 1px #AAFF00;
}

.listing-flow input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */

.listing-flow .slider.round {
    border-radius: 34px;
}

.listing-flow .slider.round:before {
    border-radius: 50%;
}

.listing-flow .modal {
    position: fixed;
    transform: translate(-50%, calc(-50% - .5px));
    top: 50%;
    left: 50%;
    background: rgba(0, 0, 0, 0.8);
    width: calc(100% + 2px);
    height: 100%;
    margin-left: -1px;
    display: none;
    z-index: 1;
    max-width: inherit;
}

.listing-flow .modalContent {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #F0F0F0;
    border: 1px solid black;
    width: 350px;
    max-width: 90%;
    padding: 10px;
    text-align: center;
}

.listing-flow .pageHeadingPolicy {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 20px;
    margin-top: 30px;
    border-bottom: 1px solid #000000;
    padding-bottom: 30px;
    text-align: center;
}

.listing-flow .pageSub {
    font-size: 21px;
    margin-bottom: 12px;
    color: #000;
    margin-top: 21px;
    font-family: Tiempos;
}

.policyDivider {
    border: none;
    background-color: #000;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.listing-flow .light-grey {
    color: #999;
    margin-bottom: 0;
}

.listing-flow .phoneInput {
    width: 136px;
}

.listing-flow #phoneModal {
    display: none
}

.listing-flow .grey-link {
    border-bottom: 1px solid #999;
}

.listing-flow .codeInput {
    width: 276px;
    letter-spacing: 10px;
}

.listing-flow small {
    font-size: 15px;
}

.listing-flow table {
    border-collapse: collapse;
    width: 100%;
}

.listing-flow tbody {
    background-color: white;
}

.listing-flow tr {
    border: 1px solid black;
}

.listing-flow td {
    margin: 0;
    padding: 0;
    height: 40px;
}

.listing-flow td:nth-of-type(4n+1) {
    text-align: left;
    padding-left: 10px;
    font-weight: 700;
}

.listing-flow .check {
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    font-size: inherit;
    text-transform: unset;
    border-radius: 0;
    color: #999;
    font-size: 12px;
    height: calc(100% + 2px);
    background-color: white;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.listing-flow .check:hover {
    background-color: #f0f0f0;
}

.listing-flow .check-img {
    background-color: #F0F0F0;
    border: 1px solid black;
    margin-top: -1px;
    margin-bottom: -1px;
    display: none;
}

.listing-flow .blackBar {
    color: #F0F0F0;
    background-color: black;
    text-align: left;
    padding: 10px;
    padding-left: 20px;
    position: relative;
}

.listing-flow .colorText {
    color: #AAFF00;
    font-weight: 500;
}

.listing-flow .photoUpload {
    padding: 20px;
    text-align: left;
    font-size: 21px;
    line-height: 24px;
    border: 1px solid black;
    margin-top: -1px;
}

.listing-flow .boxHeading {
    text-align: center;
}

.listing-flow .photoHeading {
    margin-bottom: 20px;
}

.listing-flow .photoSpace {
    border: 1px solid black;
    width: 100%;
    min-height: 100px;
}

.listing-flow .photoMagicBar {
    padding: 10px;
    border: 1px solid black;
    margin-right: -2px;
    margin-top: -1px;
    font-size: 18px;
    line-height: 21px;
}

.listing-flow .replacePhoto {
    border: 1px solid black;
    padding: 12px 24px;
    border-radius: 30px;
    font-size: 15px;
    line-height: 21px;
    cursor: pointer;
}

.listing-flow .fileUpload {
    display: none;
}

.listing-flow .uploadedPhoto {
    width: 100%;
    margin-bottom: -5px;
}

.listing-flow .adBtn {
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
    text-align: left;
    border-radius: 5px;
    text-transform: unset;
    font-size: 18px;
    padding: 12px 24px;
    position: relative;
}

.listing-flow .mB {
    margin-bottom: 20px;
}

.listing-flow .rightArrow {
    position: absolute;
    right: 20px;
    top: 34%;
}

.listing-flow .adBtn:disabled {
    background-color: #F0F0F0;
    cursor: auto;
}

.listing-flow .floatX {
    position: absolute;
    right: 20px;
    top: 1px;
    font-weight: 900;
    font-size: 30px;
}

.listing-flow .btnTime {
    position: absolute;
    right: 20px;
}

.listing-flow .rosette {
    width: 200px;
}

.listing-flow .bookingTime {
    font-size: 18px;
    line-height: 24px;
    margin-top: -123px;
    margin-bottom: 70px;
}

.listing-flow .timeRosette {
    font-size: 30px;
    line-height: 24px;
    font-weight: 700;
}

.listing-flow .dashboardPrice {
    font-family: Tiempos;
    font-size: 33px;
    line-height: 36px;
    margin-top: -15px;
}

.listing-flow .dashboardPhoto {
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 2px;
    margin-top: 20px;
}

.listing-flow .sliderSwitch {
    top: 3px;
    bottom: -3px;
}

.listing-flow .publishBtn:hover,
.listing-flow .publishBtn:active,
.listing-flow .publishBtn:focus {
    background-color: #F0F0F0;
    cursor: auto;
}

.listing-flow .pageContent {
    text-align: left;
    padding: 0px 15px;
}

.listing-flow .accordion {
    /* display: inline-table; */
    /* max-width: 638px; */
    width: 100%;
    /* border: 1px solid black; */
    margin-left: 0;
    /* margin-top: -1px; */
    box-sizing: border-box;
}

.listing-flow .dashboardAccordion {
    max-width: 638px;
    border: 1px solid black;
    margin-top: -1px;
    margin-left: 20px;
    margin-right: 20px;
}

.listing-flow .accordionHeading {
    font-size: 18px;
    padding: 20px;
    padding-left: 30px;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
}

.listing-flow .x {
    float: right;
    margin-top: -5px;
    transition: all 0.1s ease-in-out;
}

#sellingX,
#mortgageX,
#applyX,
#insuranceX,
#qualityX,
#visibilityX,
#valuationX {
    transition: all 0.1s ease-in-out;
}

.listing-flow .accordionContent {
    text-align: center;
    font-size: 15px;
    line-height: 22px;
    margin-left: 30px;
    margin-right: 30px;
    max-height: 0px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
    position: relative;
}

.listing-flow .paddingBottom {
    padding-bottom: 30px;
}

.listing-flow .whatNext {
    background-color: #000;
    color: #F0F0F0;
    max-width: 600px;
    display: inline-table;
    /* width: 100%; */
    padding: 20px;
}

.listing-flow .whatNextHeading {
    padding-top: 10px;
}

.listing-flow .whiteLine {
    border: 2px solid #F0F0F0;
    height: 0;
    width: calc(100% + 40px);
    margin-bottom: 20px;
    margin-left: -20px;
}

.listing-flow .whiteDot {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 4px solid #f0f0f0;
    border-radius: 50%;
    margin-top: -25px;
    background-color: #000;
}

.listing-flow .colorBtn {
    background-color: #AAFF00;
    color: #000;
    margin: 0;
    font-size: 18px;
    padding: 12px 30px;
}

#calculator h1 {
    font-family: Tiempos Headline;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
}

.calculator table {
    width: 100%;
    font-size: 24px;
    margin-top: 20px;
}

.calculator td:nth-child(odd) {
    text-align: right;
    width: 60%;
}

.calculator td:nth-child(even) {
    text-align: left;
    font-weight: 700;
}

.calculator button {
    font-size: 20px;
    padding: 15px;
    width: 250px;
    border-radius: 0;
    border: 1px solid black;
    margin-bottom: -1px;
    background-color: #f0f0f0;
    outline: none;
    text-align: left;
    display: inline-block;
    filter: none;
}

.calculator .activeRadio {
    background-color: #AAFF00;
}

.calculator .priceCalcInput {
    font-size: 24px;
    padding: 15px;
    width: 468px;
    border-radius: 0;
    border: 1px solid black;
    outline: none;
    margin-bottom: 15px;
}

.calculator .inputLabel {
    text-align: left;
    width: 500px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    font-family: Tiempos;
    margin-bottom: 5px;
}

.calculator .purchaseSelect {
    margin-bottom: 15px;
}

.calculator .input {
    margin-top: 15px;
}

.calculator .radio:nth-child(even) {
    margin-left: -1px;
}

.calculator .input,
.calculator .output {
    display: inline-block;
    vertical-align: middle;
    margin: 15px;
    width: 500px;
}

.calculator .output {
    width: 500px;
    display: inline-block;
    text-align: left;
    margin-top: 15px;
}

.calculator .outputBlock {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
}

.calculator .outputHeading {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

.calculator .outputExplain {
    font-size: 12px;
    font-style: italic;
}

.calculator .outputPrice {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
    font-family: Tiempos;
}

.calculator .totalSavings {
    font-size: 36px;
    color: #AAFF00;
}

.valuator a {
    text-decoration: none;
    color: white;
}

.valuator button {
    font-size: 24px;
    border: white;
    background-color: white;
    margin: 15px;
    text-decoration: none;
    outline: 0;
    font-family: Universal;
    border-radius: 0;
}

.valuator #root {
    width: 100%;
}

code {
    font-family: Universal;
}

.valuator table {
    width: 100%;
    margin-bottom: 15px;
    table-layout: fixed;
    margin-top: 10px;
}

.valuator tbody {
    background-color: #F0F0F0;
}

.valuator tr {
    border: none;
}

.valuator td {
    font-size: 16px;
}

.valuator .amount {
    font-size: 26px;
}

.valuator .altPrice {
    color: rgb(134, 134, 134);
}

.valuator .altPriceMin {
    text-align: left;
    padding-left: 15px;
}

.valuator .altPriceMax {
    text-align: right;
    padding-right: 15px;
}

.valuator .estPrice {
    font-size: 18px;
}

.valuator .priceLine {
    background-color: black;
    height: 4px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    text-align: center;
}

.valuator .priceDot {
    margin-top: -28px;
    background-color: white;
    margin-left: calc(50% - 12px);
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 4px solid black;
}

.valuator .valuation {
    font-size: 36px;
}

.valuator .context {
    text-align: left;
    padding: 15px;
}

.valuator .contextHeading {
    padding-bottom: 5px;
}

.valuator .contextText {
    padding-bottom: 5px;
}

@media (max-width: 600px) {
    .valuator .amount {
        font-size: 20px;
    }

    .valuator .valuation {
        font-size: 28px;
    }
}

@media (max-width: 500px) {
    .valuator .amount {
        font-size: 14px;
    }

    .valuator .valuation {
        font-size: 22px;
    }
}

@media (max-width: 420px) {
    .valuator .amount {
        font-size: 12px;
    }

    .valuator .valuation {
        font-size: 18px;
    }
}

.valuator .heading {
    font-size: 40px;
    font-size: 600;
    padding: 30px;
}

.valuator .card {
    display: inline-block;
    margin: 5px;
    margin-top: 0;
    margin-bottom: 15px;
    text-align: center;
    position: relative;
    width: 400px;
    max-width: 90%;
    background-color: white;
    /* box-shadow: 0 4px 4px rgb(134, 134, 134); */
    border: 1px solid rgb(37, 37, 37);
}

.valuator .cardImg {
    /* border: 1px solid rgb(37, 37, 37); */
    /* width: 400px; */
    /* height: 266.5px; */
    max-width: calc(100% - 2px);
    vertical-align: middle;
    max-height: 267px;
}

.valuator .rosette {
    width: 300px;
    max-width: 90%;
}

.valuator .cardInfo {
    padding: 15px 10px 15px 10px;
    width: auto;
    /* border: 1px solid rgb(37, 37, 37); */
    border-top: none;
    margin-top: -4px;
    border-bottom: 1px solid black;
}

.valuator .cardPrice {
    position: relative;
    font-weight: 700;
    font-size: 24px;
    font-family: Tiempos;
    margin: 5px;
}

.valuator .cardHeading {
    padding-top: 15px;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 5px;
}

.valuator .cardExtraInfo {
    position: relative;
    font-weight: 500;
    font-size: 14px;
}

.valuator .cardAddress {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    font-size: 14px;
    font-weight: 500;
}

.valuator .cardSold {
    font-size: 14px;
    font-weight: 500;
}

.valuator .scrollImages {
    background-color: white;
    width: 400px;
    max-width: 90%;
    height: 44px;
    border: 1px solid rgb(37, 37, 37);
    border-bottom: none;
    border-top: none;
    display: inline-block;
}

.valuator .leftScroll,
.valuator .rightScroll {
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgb(214, 214, 214);
    font-size: 36px;
    margin: 0;
    cursor: pointer;
    padding-top: 4px;
    padding-bottom: 4px;
}

.valuator .leftScroll {
    float: left;
    border-right: 1px solid rgb(37, 37, 37);
}

.valuator .rightScroll {
    float: right;
    border-left: 1px solid rgb(37, 37, 37);
}

.valuator .leftScroll:hover,
.valuator .rightScroll:hover {
    background-color: #AAFF00;
}

.valuator .imageNumber {
    margin: 0;
    font-size: 16px;
    height: 44px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: auto;
}

.valuator .imageNumber:hover {
    background-color: transparent;
}

.valuator .valueForm {
    text-align: center;
    position: relative;
    /* margin-top: -4px; */
}

.valuator .radioBtn {
    text-align: left;
    font-size: 20px;
    margin: 0px;
    padding: 10px;
    width: 100%;
    background-color: rgb(214, 214, 214);
    border-top: 1px solid rgb(37, 37, 37);
    /* border-right: 1px solid rgb(37, 37, 37);
	border-bottom: 1px solid rgb(37, 37, 37); */
    color: black;
    text-transform: none;
}

.valuator .readyButton:hover {
    background-color: #AAFF00;
    cursor: pointer;
}

.valuator .backBtnActive:hover {
    background-color: #AAFF00;
    cursor: pointer;
}

/* .valuator .radioDown {
	background-color: rgb(73, 0, 0);
  }
  
  .valuator .radioSame {
	background-color: rgb(102, 66, 0);
  }
  
  .valuator .radioUp {
	background-color: rgb(0, 59, 0);
  } */

.valuator .formTitle {
    font-size: 22px;
    font-weight: 600;
}

.valuator .formInputs {
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
}

.valuator .searchBar {
    /* background-color: .valuator #02bc83; */
    width: 100%;
}

.valuator .searchBtn {
    background-color: rgb(214, 214, 214);
    border: 1px solid black;
}

.valuator .postcodeInput,
.valuator .roomNumInput {
    font-size: 24px;
    margin: 15px;
    border: 1px solid black;
    background-color: rgb(214, 214, 214);
}

.valuator .modalContainer {
    z-index: 1;
    opacity: 1;
    max-height: 100%;
    height: 100%;
    width: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.95);
    top: 50%;
    left: 51%;
    transform: translate(-50%, -50%);
    padding: 1%;
    line-height: 22px;
    box-shadow: 0 10px 10px 2px black;
}

.valuator .modal {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 49%;
    width: 95%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: x mandatory;
    -ms-scroll-snap-type: x mandatory;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.valuator .closeBtn {
    color: white;
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 50px;
}

.valuator .closeBtn:hover {
    transform: scale(1.05);
}

.valuator .numPhotos {
    position: absolute;
    top: 192px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2px 10px 1px 10px;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
}

.valuator .siteButton {
    margin: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 2px 10px 1px 10px;
    border-radius: 10px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    font-size: 16px;
}

.valuator .numPhotos:hover,
.valuator .siteButton:hover {
    transform: scale(1.03);
}

.valuator .thumbnail {
    display: flex;
    padding: 5px;
    margin-top: 0px;
    max-width: calc(102% - 15px);
    -webkit-scroll-snap-align: center;
    -webkit-scroll-snap-stop: always;
    scroll-snap-stop: always;
    scroll-snap-align: center;
}

.valuator .thumbnails {
    display: inline-flex;
}

/* width */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */

::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: #3b3b3b;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes antispin {
    0% {
        transform: rotate(720deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.valuator .logo {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
}

.valuator .loader {
    border: 10px solid transparent;
    border-top: 10px solid black;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    animation: spin 1s linear infinite;
    z-index: 5;
}

.valuator .loader2 {
    border: 10px solid transparent;
    border-top: 10px solid black;
    border-radius: 50%;
    width: 220px;
    height: 220px;
    animation: antispin 1s linear infinite;
    z-index: 5;
}

.valuator .loader3 {
    border: 10px solid transparent;
    border-top: 10px solid black;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 0.4s linear infinite;
    z-index: 5;
}

.valuator #loaderButton {
    border: 10px solid transparent;
    border-top: 10px solid black;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 0.6s linear infinite;
    z-index: 5;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

.valuator .loading {
    width: auto;
}

.valuator .top {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid;
    border-radius: 10px;
}

.valuator .middle-container {
    display: block;
    /* min-height: 65vh; */
}

.valuator .section-content {
    z-index: 1;
    padding-top: 1px;
    padding-bottom: 5px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.valuator .finishText {
    font-size: 20px;
    font-weight: 700;
}

.valuator .finishExText {
    font-size: 20px;
    font-weight: 400;
}

.valuator .finishPrice {
    font-size: 30px;
    font-weight: 700;
    color: black;
    margin-top: -170px;
    margin-bottom: 122px;
}

.valuator .backBtn {
    font-size: 24px;
    font-family: 500;
    padding: 5px;
    cursor: pointer;
    width: 201px;
    height: 44px;
    max-width: calc(45% + 1px);
    margin: 0;
    background-color: rgb(214, 214, 214);
    border: 1px solid rgb(37, 37, 37);
}

.valuator .backBtn2 {
    border-left: none;
}

.valuator .fas {
    font-size: 16px;
    vertical-align: middle;
}

.valuator .error {
    font-size: 40px;
    font-weight: 600;
}

.valuator .priceCircle {
    border: 2px solid black;
    border-radius: 50%;
    margin-left: 25%;
    margin-right: 25%;
    padding-bottom: 15%;
    padding-top: 15%;
    margin-bottom: 15px;
}

.valuator .worthText {
    text-align: left;
    font-family: Universal;
    font-size: 20px;
    padding: 14px;
}

@media (max-width: 680px) {
    .listing-flow .blackBar {
        margin-left: -25px;
        margin-right: -25px;
    }

    .listing-flow .photoUpload {
        margin-left: -20px;
        margin-right: -20px;
    }

    .listing-flow .dashboardAccordion {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .listing-flow .accordion {
        max-width: 100%;
    }
}

a:-webkit-any-link {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.viewingBtn {
    border: 1px solid black;
    margin-top: -1px;
    padding: 10px;
    text-align: left;
    font-size: 15px;
    cursor: pointer;
    position: relative;
}

.viewingBtnName {
    font-size: 18px;
}

.viewingBtnStatus {
    margin-top: 5px;
}

.listing-flow .viewingHeading {
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
    font-weight: 900;
}

.viewingPastBtn {
    border-top: 1px solid black;
    padding-top: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-left: -20px;
    margin-right: -20px;
    padding: 20px;
}

@media(max-width: 680px) {
    .viewingPastBtn {
        margin-left: -40px;
        margin-right: -40px;
        padding: 20px;
    }
}

.viewingBtnArrow {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.listing-flow .calc-btn {
    text-align: center;
    max-width: calc(33% - 0px);
}

.listing-flow .dot-calc-btn {
    color: transparent;
}

.listing-flow .callbackBox {
    border: 1px solid black;
    border-radius: 5px;
    padding: 20px;
}

.listing-flow .callbackHeading {
    font-size: 24px;
    margin-bottom: 20px;
}

.listing-flow .dot-calc-btn:hover {
    background-color: transparent;
    cursor: auto
}

.propCard {
    border: 1px solid black;
    display: inline-block;
    width: 250px;
    margin: 5px;
    padding: 10px;
    cursor: pointer;
}

#landingPage button {
    text-transform: uppercase;
    font-size: 21px;
    padding: 15px 45px 15px 45px;
    border-radius: 45px;
    background-color: transparent;
    margin: 30px 0px 50px 0px;
    line-height: 21px;
    letter-spacing: 0.06em;
    outline: 0;
}

#landingPage button:hover {
    transform: scale(1.02);
    cursor: pointer;
}

#landingPage button:active {
    border: none;
    outline: 0;
}

#landingPage .whiteBtn {
    border: 2px solid white;
    color: white;
}

#landingPage .blackBtn {
    border: 2px solid black;
    color: black;
}

#landingPage .heading {
    font-size: 42px;
    line-height: 45px;
    font-family: Tiempos;
    padding-bottom: 20px;
}

@media (min-width:1125px) {
    #landingPage .heading {
        font-size: 48px;
        line-height: 54px;
        font-family: Tiempos;
        padding-bottom: 20px;
    }
}

#landingPage .topBlock {
    width: 100%;
    background-color: black;
    color: white;
    font-family: Universal;
    font-size: 18px;
    overflow: hidden;
}

#landingPage .cuppaBox,
#landingPage .storyBox {
    width: 100%;
    background-color: black;
    color: white;
}

#landingPage .backgroundText {
    color: rgb(100, 100, 100);
    font-family: Tiempos;
    font-size: 42px;
    width: 3100px;
    margin-left: -156px;
    /* margin-right: -50vw; */
    position: relative;
    margin-top: -50px;
    height: 300px;
    overflow: hidden;
}

#landingPage .wobbleCircle {
    position: relative;
    top: -270px;
}

#landingPage .wobbleContent {
    background-color: #AAFF00;
    /* height: 300px; */
    color: black;
    text-transform: uppercase;
    padding: 150px 50px 150px 50px;
    border-radius: 50%;
    width: 300px;
    font-size: 40px;
    font-weight: 600;
    display: inline-block;
}

#landingPage .circleContainer {
    width: 100%;
    margin-bottom: 150px;
    margin-top: 30px;
}

#landingPage .logo {
    margin: 30px;
    width: 100px;
}

#landingPage .explanationHeading {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

/* @media (min-width:1125px){
	.explanationHeading {
	  font-size: 45px;
	}
  } */

#landingPage .mortgagePrinciple {
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

#landingPage .quoteBox {
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px;
    margin: 30px;
    margin-left: 0;
    margin-right: 0;
}

#landingPage table {
    width: 100%;
}

#landingPage .altTable {
    display: none;
}

@media (max-width:1125px) {
    #landingPage table {
        display: none;
    }

    #landingPage .altTable {
        display: block;
    }
}

#landingPage .youtubeLink {
    color: white;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
    border-bottom: 1px solid white;
}

#landingPage .rayVideo {
    width: 560px;
    height: 315px;
    max-width: 90vw;
    max-height: 50.625vw;
    border: none;
    margin-top: 15px;
    margin-bottom: 50px;
}

@media (max-width:1125px) {
    #landingPage .rayVideo {
        margin-bottom: 30px;
    }
}

#landingPage .youtubeLinkDiv {
    padding-bottom: 50px;
}

#landingPage .quoteContent {
    font-family: Tiempos;
    font-size: 30px;
    line-height: 45px;
}

#landingPage .quoteAuthor {
    padding-top: 10px;
    font-size: 18px;
}

#landingPage .quoteResponse {
    font-family: Tiempos;
    font-size: 45px;
    margin-bottom: 20px;
    line-height: 48px;
}

#landingPage .quoteResponseContent {
    font-size: 18px;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width:1125px) {
    #landingPage .quoteContent {
        font-size: 24px;
        line-height: 30px;
    }
}

#landingPage .cuppaHeading {
    font-size: 12px;
    font-family: Universal;
    padding-top: 20px;
    padding-bottom: 20px;
    letter-spacing: 2px;
}

#landingPage .catchBox,
#landingPage .jobBox,
#landingPage .otherServices,
#landingPage .topHeader {
    padding-left: 20px;
    padding-right: 20px;
}

#landingPage .cuppaContent,
#landingPage .catchContent,
#landingPage .cuppaContentTable {
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

#landingPage .cuppaContentTable {
    padding: 0;
}

#landingPage .catchContent {
    margin-bottom: 50px;
}

#landingPage .cuppaBox {
    width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
}

#landingPage .tvIcon {
    margin-bottom: -10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 40px;
}

#landingPage .rosette {
    width: 250px;
    max-width: 100%;
}

#landingPage .rosetteText {
    font-weight: 600;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.06em;
    color: black;
    margin-top: -421px;
    position: relative;
    text-transform: uppercase;
}

#landingPage .catchHeading {
    font-size: 45px;
    font-family: Tiempos;
    /* padding-top: 30px;
	padding-bottom: 30px; */
    margin-top: 40px;
    line-height: 48px;
    padding-bottom: 20px;
}

#landingPage .fishImage {
    margin-top: 30px;
    margin-bottom: 30px;
}

#landingPage .jobHeading {
    font-size: 45px;
    font-family: Tiempos;
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 48px;
}

#landingPage .jobContent {
    font-size: 18px;
}

#landingPage .emailLink {
    font-size: 18px;
    color: black;
    text-decoration: none;
    border-bottom: 1px solid black;
}

#landingPage .emailLinkDiv {
    margin-top: 20px;
    margin-bottom: 50px;
}

#landingPage .accordion {
    display: inline-table;
    max-width: 900px;
    width: 100%;
    border: 1px solid black;
    margin-left: -1px;
    margin-top: -1px;
}

#landingPage .accordionHeading {
    font-size: 18px;
    padding: 20px;
    padding-left: 30px;
    text-align: left;
    font-weight: 700;
    cursor: pointer;
}

#landingPage .x {
    float: right;
    margin-top: -5px;
    transition: all 0.1s ease-in-out;
}

#landingPage #sellingX,
#landingPage #mortgageX,
#landingPage #applyX,
#landingPage #insuranceX {
    transition: all 0.1s ease-in-out;
}

#landingPage .accordionContent {
    text-align: left;
    font-size: 15px;
    line-height: 22px;
    margin-left: 30px;
    margin-right: 30px;
    max-height: 0px;
    transition: all 0.1s ease-in-out;
    overflow: hidden;
}

#landingPage .paddingBottom {
    padding-bottom: 30px;
}

#landingPage .otherServices {
    margin-bottom: 30px;
}

#landingPage .mention {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
}

#landingPage .socialIcon {
    height: 50px;
    margin: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
}

#landingPage hr {
    border: none;
    border-top: 1px solid black;
}

#landingPage .legal {
    font-size: 15px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

@media (min-width:800px) {
    #landingPage .mortgagePrinciple {
        font-size: 30px;
        padding-left: 10%;
        padding-right: 10%;
    }

    #landingPage .quoteBox {
        margin-left: 30px;
        margin-right: 30px;
    }

    #landingPage .catchBox,
    #landingPage .jobBox,
    #landingPage .otherServices,
    #landingPage .topHeader {
        padding-left: 10%;
        padding-right: 10%;
    }
}

#landingPage .accordionLink {
    text-decoration: none;
    color: black;
    border-bottom: 1px solid black;
    font-size: 15px;
}

#landingPage .accordionLinkDiv {
    padding-top: 20px;
    padding-bottom: 30px;
}

#optimiser .main {
    height: calc(100% - 42px);
}

#optimiser ul {
    list-style-type: none;
    padding: 0%;
    margin-top: 0px;
    margin-bottom: 0;
}

#optimiser li {
    font-size: 13px;
}

#optimiser .main {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    ;
    text-align: center;
    height: 100%;
}

#optimiser .optimiser-td {
    /* padding: 15px 30px 15px 30px; */
    background-color: white;
    /* border-radius: 10px; */
    margin: 4px;
    /* color: white; */
    display: inline-block;
    vertical-align: top;
    border: 1px solid black;
    min-height: 272px;
    min-width: 62px;
    padding: 4px;
}

#optimiser .optimiser-page {
    color: black;
    font-size: 16px;
    font-weight: 600;
}

#optimiser .inline {
    display: inline-block;
    vertical-align: top;
}

#optimiser .otherProperties {
    /* height: 10px; */
    font-size: 15px;
    color: #000;
}

@media (max-height: 600px) {
    #optimiser .optimiser-td {
        padding: 5px 30px 5px 30px;
    }

    #optimiser .optimiser-page {
        font-size: 20px;
    }
}

#optimiser .optimiser-user-price {
    background-color: black;
    color: white;
    text-shadow: 0px 0px 1px black;
    /* margin-bottom: -11px; */
    margin-left: -5px;
    margin-right: -5px;
}

#optimiser table {
    width: 100%;
    height: 100%;
    table-layout: fixed;
    overflow-y: hidden;
    border: none;
    border-collapse: collapse;
}

#optimiser td {
    width: 50%;
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    -webkit-scroll-snap-align: start;
    scroll-snap-align: start;
}

#optimiser .scrollTable {
    position: relative;
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
}

#optimiser .optimiser-table {
    overflow: auto;
    white-space: nowrap;
    scroll-behavior: smooth;
}

#optimiser .fadeLeft {
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100px;
    height: 100%;
    z-index: -1;
}

#optimiser .fadeRight {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: -1;
}

#optimiser .alterPrice {
    font-size: 50px;
    vertical-align: middle;
    z-index: 1;
}

#optimiser .alterPriceText {
    padding-top: 5px;
    font-size: 18px;
    font-weight: 600;
    vertical-align: middle;
    z-index: 1;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes antispin {
    0% {
        transform: rotate(720deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

#optimiser .logo {
    width: 200px;
    position: absolute;
    top: 12%;
    left: 11%;
}

/* 
#optimiser .loader {
	border: 10px solid transparent;
	border-top: 10px solid #02bc83;
	border-radius: 50%;
	width: 240px;
	height: 240px;
	animation: spin 1s linear infinite;
	z-index: 5;
} */

/* #optimiser .loader2 {
	border: 10px solid transparent;
	border-top: 10px solid #02bc83;
	border-radius: 50%;
	width: 220px;
	height: 220px;
	animation: antispin 1s linear infinite;
	z-index: 5;
}

#optimiser .loader3 {
	border: 10px solid transparent;
	border-top: 10px solid #02bc83;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	animation: spin 0.4s linear infinite;
	z-index: 5;
}

#optimiser .loading {
	width: auto;
} */

#optimiser .top {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0px solid;
    border-radius: 10px;
}

#optimiser .middle-container {
    display: block;
    /* min-height: 65vh; */
}

#optimiser .section-content {
    z-index: 1;
    padding-top: 1px;
    padding-bottom: 5px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#optimiser .uk-navbar {
    overflow: hidden;
    display: flex;
    position: relative;
    background: #f6f6f6 !important;
    padding: 0 17px;
}

#optimiser .App {
    width: 100%;
}

#optimiser .uk-logo {
    font-size: 1.1875rem;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    color: #666;
    text-decoration: none;
    font-family: Oswald;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 0px;
    color: #ccc;
}

#optimiser .uk-navbar-item,
#optimiser .uk-navbar-nav>li>a,
#optimiser .uk-navbar-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    min-height: 80px;
    padding: 0 15px;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    text-decoration: none;
}

#optimiser .uk-navbar-item {
    color: #666;
    min-height: 42px;
    padding: 0;
}

#optimiser label {
    font-size: 22px;
    font-weight: 600;
}

#optimiser input {
    text-align: center;
    padding: 1px 2px;
    padding-right: 13px;
    border: 1px solid black;
    font-size: 21px;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-right: -1px;
    margin-left: -1px;
    height: 36px;
    width: 60%;
    border-radius: 0;
}

#optimiser #priceInput {
    padding-left: 15px;
    padding-right: 0px;
}

#optimiser #locationInput {
    margin-right: 0px;
}

#optimiser .score {
    color: rgb(143, 143, 143);
    font-weight: 700;
}

#optimiser a {
    text-decoration: none;
}

#optimiser a:visited {
    /* color: #00694a; */
}

#optimiser a:hover {
    text-shadow: 0px 0px 1px;
}

#optimiser .updateBtn {
    width: 40%;
    padding: 5px;
    margin-top: 10px;
    background-color: #02bc83;
    border: none;
    font-size: 24px;
    color: white;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3);
}

#optimiser .positionBtn {
    width: 40%;
    padding: 5px;
    margin-top: 10px;
    background-color: #02bc83;
    border: none;
    font-size: 14px;
    color: white;
    vertical-align: middle;
    cursor: pointer;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .3);
}

#optimiser .incrBtn {
    margin-left: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

#optimiser .decrBtn {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 5px;
    background-color: #bc0202;
}

#optimiser .incrBtn:hover,
#optimiser .decrBtn:hover {
    transform: scale(1.02);
}

#optimiser .disableBtn {
    background-color: rgb(143, 143, 143);
    color: rgb(63, 63, 63);
}

#optimiser .disableBtn:hover {
    transform: none;
}

#optimiser .startBtn {
    padding: 15px;
    width: 50%;
}

#optimiser .scrollBtn {
    width: 100%;
    padding: 5px;
    background-color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    color: black;
    vertical-align: middle;
    cursor: pointer;
}

#optimiser .backBtn {
    color: black;
}

#optimiser .updateBtn:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.05);
}

#optimiser input[type=number]::-webkit-inner-spin-button,
#optimiser input[type=number]::-webkit-outer-spin-button {
    opacity: 0;
    display: none;
}

#optimiser input::-webkit-calendar-picker-indicator {
    opacity: 0;
    display: none;
}

#optimiser .note {
    margin-top: -10px;
    font-size: 20px;
    vertical-align: middle;
}

#optimiser .notes {
    padding-left: 15px;
    padding-right: 15px;
}

#optimiser #clickNote {
    position: absolute;
    top: 50%;
    left: -100px;
    font-weight: 700;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

#optimiser .note2 {
    margin-top: -10px;
    font-size: 18px;
}

#optimiser .scrollToPages {
    font-size: 30px;
    vertical-align: middle;
    z-index: 2;
}

@media (max-width:847px) {
    #optimiser table {
        width: 100%;
    }

    #optimiser .scrollBtn {
        display: block;
    }
}

@media (max-height: 620px) {
    #optimiser .disappearOnSmallerScreen {
        display: none;
    }

    #optimiser label {
        font-size: 20px;
    }

    #optimiser .note {
        font-size: 18px;
    }

    #optimiser input {
        font-size: 20px;
    }
}

#optimiser .formContent {
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    max-height: 100%;
}

#optimiser #formHouse {
    padding-top: 42px;
    padding-bottom: 26px;
}

#optimiser #slide1,
#optimiser #slide2,
#optimiser #slide3 {
    -webkit-scroll-snap-align: start;
    -webkit-scroll-snap-stop: always;
    scroll-snap-stop: always;
    scroll-snap-align: start;
}

#optimiser small {
    z-index: 2;
}

#optimiser .firstBreak {
    display: none;
}

@media (min-height: 650px) {
    #optimiser .firstBreak {
        display: block;
    }

    #optimiser .alterPriceText {
        font-size: 22px;
    }
}

#optimiser .secondBreak {
    display: none;
}

@media (min-height: 700px) {
    #optimiser .secondBreak {
        display: block;
    }

    #optimiser li {
        font-size: 16px;
    }
}

@media (min-height: 800px) {
    #optimiser li {
        font-size: 18px;
    }

    #optimiser .optimiser-page {
        font-size: 26px;
    }
}

#optimiser #comment {
    font-size: 28px;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
}

#optimiser #fillComment {
    font-size: 20px;
}

#optimiser .mainLogo {
    width: 200px;
}

#optimiser .mainMenu {
    width: calc(100% - 30px);
}

#optimiser .poundSign {
    position: absolute;
    margin-left: 42px;
    margin-top: 12px;
    color: black;
    font-size: 24px;
}

@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    #optimiser .poundSign {
        margin-top: 7px;
    }
}

@media not all and (min-resolution:.001dpcm) {
    #optimiser .poundSign {
        margin-top: 7px;
    }
}

#optimiser .tooltip {
    position: relative;
    display: inline-block;
    font-size: 22px;
}

#optimiser .tooltiptext {
    font-size: 14px;
    font-weight: 400;
}

#optimiser .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(34, 34, 34);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    top: calc(-50% - 5px);
    right: 125%;
}

#optimiser .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent rgb(34, 34, 34);
}

#optimiser .tooltip:hover .tooltiptext {
    visibility: visible;
}

#optimiser .negative,
#optimiser .plus {
    background-color: rgb(214, 214, 214);
    border: 1px solid black;
    border-radius: 0;
    font-size: 20px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 5px;
}

/* .negative:hover {
	background-color: #02bc83;
  } */

/* .plus:hover {
	background-color: #02bc83;
  } */

#optimiser .negative:focus {
    outline: none;
}

#optimiser .propertyForm {
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
}

#optimiser .listItem {
    padding-left: 10px;
    padding-right: 10px;
}

#optimiser .context {
    text-align: left;
    padding: 15px;
}

#optimiser .contextHeading {
    padding-bottom: 5px;
}

#optimiser .contextText {
    padding-bottom: 5px;
}

#optimiser .saveBtn {
    /* height: 40px; */
    font-size: 24px;
    border-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid black;
}

#optimiser .percentage {
    padding: 15px;
    padding-top: 5px;
}

#optimiser .billAndTed {
    position: absolute;
    left: calc(50% - 100px);
    top: calc(50% - 75px);
}

#optimiser .gifClose {
    position: absolute;
    top: 0;
    color: white;
    right: 6px;
    font-size: 20px;
    cursor: pointer;
}

#optimiser .gifClose:hover {
    transform: scale(1.2);
}

.listing-flow .viewingNumber {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid black;
    width: 30px;
    text-align: center;
    border-radius: 50%;
}

#calculator {
    max-width: 100%;
}

#calculator table {
    width: 100%;
    font-size: 24px;
    margin-top: 20px;
}

#calculator td:nth-child(odd) {
    text-align: right;
    width: 60%;
}

#calculator td:nth-child(even) {
    text-align: left;
    font-weight: 700;
}

#calculator button {
    font-size: 20px;
    padding: 15px;
    width: 250px;
    border-radius: 0;
    border: 1px solid black;
    margin-bottom: -1px;
    background-color: #f0f0f0;
    outline: none;
    text-align: left;
    display: inline-block;
    filter: none;
}

#calculator .activeRadio {
    background-color: #AAFF00;
}

#calculator .priceInput {
    font-size: 24px;
    padding: 15px;
    width: 468px;
    border-radius: 0;
    border: 1px solid black;
    outline: none;
    margin-bottom: 15px;
    text-align: left;
}

@media (max-width: 548px) {
    #calculator .priceInput {
        width: 222px;
    }
}

#calculator .inputLabel {
    text-align: left;
    width: 500px;
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    font-family: Tiempos;
    margin-bottom: 5px;
}

#calculator .purchaseSelect {
    margin-bottom: 15px;
}

#calculator .input {
    margin-top: 15px;
}

#calculator .radio:nth-child(even) {
    margin-left: -1px;
}

#calculator .input,
#calculator .output {
    display: inline-block;
    vertical-align: middle;
    margin: 15px;
    width: 500px;
    max-width: calc(100% - 50px);
}

#calculator .output {
    width: 500px;
    display: inline-block;
    text-align: left;
    margin-top: 15px;
}

#calculator .outputBlock {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
}

#calculator .outputHeading {
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 5px;
}

#calculator .outputExplain {
    font-size: 12px;
    font-style: italic;
}

#calculator .outputPrice {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 2px;
    font-family: Tiempos;
}

#calculator .totalSavings {
    font-size: 36px;
    color: #AAFF00;
}

.listing-flow .viewingRadio {
    margin: 0;
    border-radius: 0;
    width: 150px;
    margin-top: -1px;
    max-width: 50%;
}

.listing-flow .viewingRadio:nth-child(2n) {
    margin-left: -1px;
}

.listing-flow .dayDate {
    font-weight: 800;
    /* margin-bottom: 20px; */
    width: 267px;
    display: inline-block;
    padding: 15px;
    border: 1px solid black;
    background-color: white;
    max-width: calc(100% - 32px);
}

.listing-flow .viewingCard {
    width: 400px;
    max-width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}

.listing-flow .viewingArrows {
    font-size: 20px;
    /* padding: 10px; */
    background-color: #f0f0f0;
    margin: -15px;
    padding: 15px;
}

.listing-flow .viewingArrows:nth-child(1) {
    float: left;
    border-right: 1px solid black;
}

.listing-flow .viewingArrows:nth-child(2) {
    float: right;
    border-left: 1px solid black;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

/* .listing-flow .blackPage {
	  margin-left: -20px;
	  margin-right: -20px;
	  background-color: #000;
	  height: 100vh;
  } */

.listing-flow .whiteText {
    color: #F0F0F0;
}

.logoWhite {
    width: 50px;
    margin-bottom: -20px;
    margin-top: 20px;
}

.freeExperts {
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.videoBox {
    border: 1px solid #F0F0F0;
    margin: 20px;
    height: 100px;
}

.borderTop {
    border-top: 1px solid #f0f0f0;
}

.listing-flow .qualityImage {
    display: inline-block;
    vertical-align: middle;
}

.listing-flow .qualityImagesContainer {
    border: 1px solid black;
    overflow: hidden;
    /* max-height: 200px; */
}

.listing-flow .qualityImagesExtension {
    margin-bottom: 0px;
}

.listing-flow .imageNumberBox:hover {
    background-color: transparent;
}

.detailTable tr {
    border: 1px solid #999;
}

.detailTable td:nth-of-type(2n) {
    text-align: right;
    padding-right: 20px;
}

.listing-flow .frameName {
    text-transform: uppercase;
    font-size: 13px;
    margin: 10px;
    letter-spacing: 1px;
}

.listing-flow .mipTable tr {
    border: none;
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;
}

.listing-flow .mipTable td:nth-child(2n) {
    text-align: right;
    /* padding-right: 30px; */
    font-size: 22px;
    /* font-weight: 700; */
}

.listing-flow .mipTable td:nth-child(2n - 1) {
    color: #999;
    padding-left: 0;
}

.listing-flow .mipTable td {
    height: 42px;
}

.listing-flow .lender-logo {
    max-height: 60px;
    max-width: 140px;
}

.listing-flow .dashedLoading {
    border: 4px dotted black;
    border-top: 4px solid transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: inline-block;
    animation: spin 0.6s infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.listing-flow .footer {
    background-color: #000;
    color: #f0f0f0;
    padding: 20px 30px 30px;
    margin-top: 10px;
}

.construction .slideBody {
    position: absolute;
    /* background-color: #AAFF00; */
    color: black;
    text-align: center;
    padding: 30px;
    border-radius: 30px;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 5em;
    font-family: Tiempos;
}

.construction .rosette {
    width: 60%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    max-width: 556px;
}

.construction .shortly {
    font-size: 32px;
    color: black;
    font-family: Universal;
}

.construction a {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid black;
    font-weight: 600;
}

.construction .backgroundText {
    color: rgb(100, 100, 100);
    font-family: Tiempos;
    font-size: 50px;
    width: 3400px;
    margin-left: -156px;
    /* margin-right: -50vw; */
    position: relative;
    margin-top: -50px;
    overflow: hidden;
}

.listing-flow .country_code {
    font-size: 24px;
    padding: 10px;
    width: 141px;
    border: 1px solid black;
    /* line-height: 30px; */
    height: 52px;
    outline: none;
    vertical-align: top;
}

.listing-flow .country_code option {
    font-size: 16px;
}

.listing-flow .nav-item {
    display: inline-block;
    padding: 15px 35px 15px 35px;
    background-color: #999;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    width: calc(50% - 72px);
}

.listing-flow .nav-item-active {
    background-color: #f0f0f0;
}

.listing-flow .nav-item:nth-child(1) {
    margin-right: 2px;
}

.listing-flow .nav-item:nth-child(2) {
    margin-left: 2px;
}

.listing-flow .home-nav {
    display: inline-block;
    width: calc(100% - 160px);
}

.listing-flow .footer-bar {
    background-color: #f0f0f0;
    position: fixed;
    transform: translate(-50%, 0px);
    bottom: 0px;
    left: 50%;
    width: 100%;
    /* max-width: 680px; */
    border-top: 1px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    line-height: 15px;
    z-index: 0;
}

.listing-flow .footer-bar-item {
    display: inline-block;
    width: 33%;
    color: #999;
    cursor: pointer;
    font-weight: 504;
}

.listing-flow .footer-bar-item-active {
    color: black;
}

/* .listing-flow .listing-flow {
	margin-bottom: 80px;
} */

.listing-flow .successModal {
    display: none;
    position: absolute;
    transform: translate(-50%, 0px);
    top: 20px;
    left: 50%;
    background-color: #AAFF00;
    border: 1px solid black;
    width: 280px;
    padding: 20px;
}

.listing-flow .gotItBtn {
    text-transform: initial;
    font-size: 18px;
    padding: 5px 20px 5px 20px;
    margin: 0;
    background-color: transparent;
}

.listing-flow .bedroomNumberSelect {
    font-size: 24px;
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    margin-top: 10px;
    width: 314px;
}

.loading {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.loading .loader1 {
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    border: 10px solid black;
    border-bottom: 10px solid transparent;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    animation: spin 0.8s infinite;
    animation-timing-function: linear;
}

.loading .loader2 {
    position: absolute;
    top: calc(50% - 65px);
    left: calc(50% - 65px);
    border: 10px solid black;
    border-bottom: 10px solid transparent;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    animation: antispin 1s infinite;
    animation-timing-function: linear;
}

.loading .loader3 {
    position: absolute;
    top: calc(50% - 70px);
    left: calc(50% - 70px);
    border: 10px solid black;
    border-bottom: 10px solid transparent;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    animation: spin 2s infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes antispin {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.listing-flow .paddingBottom {
    height: 20px;
}

.listing-flow .agreeBox {
    border: 1px solid black;
    border-radius: 2px;
    padding: 10px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: left;
}

.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
}

.checkbox>span {
    padding: 0.5rem 0.25rem;
    font-size: 16px;
}

.checkbox>input {
    height: 25px;
    width: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid black;
    border-radius: 2px;
    outline: none;
    transition-duration: 0.3s;
    background-color: white;
    cursor: pointer;
}

.checkbox>input:checked {
    background-color: black;
}

.checkbox:hover>input:checked {
    background-color: rgb(20, 20, 20);
}

.checkbox>input:checked+span::before {
    content: '\2713';
    display: block;
    text-align: center;
    font-weight: 900;
    color: white;
    position: absolute;
    left: 0.7rem;
    top: 0.2rem;
}

.bm-morph-shape {
    fill: black;
}

.bm-menu {
    background: black;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: scroll;
}

.bm-cross-button {
    right: 20px !important;
    top: 5px !important;
}

.bm-burger-button {
    position: absolute;
    width: 29px;
    height: 25px;
    right: 28px;
    top: 24px;
    /* z-index: 0!important; */
    float: right;
    outline: none;
}

.bm-burger-button button {
    outline: none;
}

.bm-burger-bars {
    background: white;
}

.bm-burger-bars-hover {
    background: #999;
    opacity: 1 !important;
}

.bm-icon {
    width: 36px;
    height: 36px;
    font-size: 36px;
    margin-top: -6px;
    margin-right: -3px;
    color: black;
}

.bm-icon:hover {
    color: #999;
    opacity: 1 !important;
}

.bm-burger-button:hover .bm-icon {
    color: #999;
    opacity: 1 !important;
}

.bm-item {
    border: none;
    text-align: left;
    margin-bottom: 20px;
    outline: none;
    cursor: pointer;
    font-weight: 100;
    font-size: 16px;
}

a.bm-item {
    color: white;
    font-size: 15px;
}

hr.bm-item {
    border: 1px solid white;
}

.bm-cross {
    height: 30px !important;
    width: 5px !important;
    background: white;
}

.bm-cross-button button {
    outline: none;
}

.grey-button {
    background-color: lightgrey!important
}

.grey-button:hover {
    background-color: grey!important;
}

.grey-button:active {
    background-color: grey!important;
}

.red-button {
    background-color: red!important;
}

.red-button:hover {
    background-color: darkred!important;
}

.red-button:active {
    background-color: darkred!important;
}

.loading-spinner {
    border: 4px solid transparent;
    border-top: 4px solid black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
    z-index: 5;
}

.description-edit {
    border: 1px solid black;
    border-radius: 5px;
    padding: 12px 24px;
    background-color: #f0f0f0;
}

.description-edit .title-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.description-edit .title-row button {
    padding: 0px;
    margin: 0px;
    border-radius: 5px;
    display: flex;
}

.description-edit .edit-desc-button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}