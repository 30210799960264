.logo {
    width: 10px 0 0 0;
    margin: 30px;
    margin-bottom: 0;
    margin-top: 21px; }

.heading {
    padding: 0px 15px;
    text-align: left;
    font-family: $heading_font;
    font-size: 30px;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 20px;
    margin-top: 10px;
    color: $heading; }

.content {
    text-align: left;
    font-family: $content_font;
    font-size: 15px;
    line-height: 21px;
    font-weight: 400;
    margin-bottom: 20px;
    padding: 0px 15px;
    color: $content; }

.applicantReference {
    text-align: left;
    padding: 15px;
    color: $content; }

.capBtn {
    border-radius: 0px;
    color: $button_text;
    background-color: $button_background;

    &:disabled {
        background-color: #e8e8e8;
        color: grey;
        border: 1px solid grey; } }


.accordion_radio {
    padding-left: 0px; }

.accordion_button {
    display: inline-block;
    cursor: pointer; }

.accordion {
    overflow: hidden;
    transition: 0.2s all ease-in-out; }

.flow {
    .accordion_button {
        font-family: $content_font;
        background-color: transparent;
        padding: 15px 21px;
        line-height: 30px;
        border: 1px solid $radio_text;
        outline: none;
        cursor: pointer;
        width: calc(100% - 44px); } }

.info {
    cursor: pointer;
    display: inline-block;
    padding: 0px;
    margin-left: 15px;
    border-bottom: 1px solid $info_color; }
