input {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
    padding: 10px;
    border: 1px solid $input_text;
    outline: none;
    border-radius: 0;
    max-width: calc(100% - 20px);
    background-color: $input_background;
    color: $input_text; }

.input_contain {
    text-align: left;
    padding: 0px 15px;

    .capBtn {
        border-radius: 0px;
        color: $button_text;
        background-color: $button_background;

        &:disabled {
            background-color: $disabled;
            color: $greyed_out;
            border: 1px solid $greyed_out; } }

    .input_wrap {
        position: relative;
        text-align: left;

        input {
            width: $width;
            font-family: $content_font; }

        .pound_sign {
            position: absolute;
            font-size: 24px;
            transform: translate(0px, -50%);
            top: calc(50% - 1px);
            left: 10px; }

        .following_label {
            position: absolute;
            font-size: 24px;
            transform: translate(0px, -50%);
            top: calc(50% - 1px);
            right: 10px; }

        select {
            outline: none;
            border: 1px solid $input_text;
            color: $input_text;
            font-size: 24px;
            padding: 10px;
            background-color: $input_background;

            &.day {
                width: calc(33% - 20px);
                margin-right: 10px; }

            &.month {
                width: calc(33% + 15px); }

            &.year {
                width: calc(33% - 15px);
                margin-left: 10px; } } } }
