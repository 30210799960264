@import "./_variables";

.landing {
    text-align: left;
    padding-right: 15px;
    background-color: white;

    .display {
        min-height: 100vh; }

    .logo_wrap {
        text-align: left;
        margin: 0px 150px;
        padding-top: 20px;

        .logo {
            width: 120px; } }

    .heading {
        font-size: 72px;
        line-height: 82px;
        font-family: 'Oswald', sans-serif;
        margin: 0px 150px;
        padding-top: 20px;
        font-weight: 500;

        .free {
            display: inline-block;
            border-bottom: 4px solid black; } }

    .mission {
        font-size: 72px;
        line-height: 82px;
        font-family: 'Oswald', sans-serif;
        margin: 0px 150px;
        // padding-top: 30px
        text-transform: uppercase;
        color: #AAFF00;
        letter-spacing: -0.5px;
        padding: 5px 0px;
        font-weight: 500;

        .mission_u {
            display: inline-block;
            // font-weight: 700
            border-bottom: 4px solid #AAFF00; } }

    .join {
        margin-left: 40px;
        font-size: 30px;
        padding-top: 20px; }

    .content_section {
        margin: 0px 150px;
        padding-top: 30px;

        .meantime_section {
            display: inline-block;
            vertical-align: middle;
            width: calc(100% / 2 - 20px);
            text-align: center;

            .heading {
                font-size: 26px;
                // margin-left: 0px
                margin: 0px;
                line-height: 36px; }

            .mail_chimp {

                input {
                    font-family: 'Oswald', sans-serif;
                    border-radius: 0px;
                    border: 1px solid black;
                    padding: 5px;
                    font-size: 20px;
                    outline: none; }

                button {
                    font-size: 20px;
                    font-family: 'Oswald', sans-serif;
                    border-radius: 0px;
                    border: 1px solid black;
                    padding: 5px;
                    background-color: #AAFF00;
                    color: black;
                    margin-left: -1px;
                    outline: none; } }

            .arrow {} }

        .or_hr {
            display: inline-block;
            vertical-align: text-top;
            width: 40px;
            text-align: center;

            .or_divide {
                display: inline-block;
                font-family: Tiempos;
                width: 9px;
                text-align: center;
                border-right: 1px solid black;
                padding: 20px 0px;
                margin-left: -9px;

                .or {
                    margin-left: -2px;
                    background-color: white;
                    width: 22px;
                    padding: 2px 0px;
                    font-weight: 800; } } } }

    .other_section {

        .tools {
            margin-left: 40px;
            padding-top: 40px;
            display: inline-block;
            vertical-align: top;
            width: 303px;
            text-align: left;

            .heading {
                font-size: 30px;
                line-height: 30px;
                margin-left: 0px; }

            ul {
                padding-inline-start: 0px;

                li {
                    cursor: pointer;
                    margin: 0;
                    padding: 20px 0px 20px 50px;
                    list-style: none;
                    background-image: url("./img/logo.svg");
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: 40px;

                    .tool {
                        font-size: 24px;
                        line-height: 30px; } } } }

        .videos {
            margin-left: 40px;
            padding-top: 40px;
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 383px);
            max-width: 100%;
            text-align: left;

            .heading {
                text-align: left;
                font-size: 30px;
                line-height: 30px;
                margin-left: 0px;
                padding-bottom: 10px; }

            .video {
                display: inline-block;

                iframe {
                    border: none;
                    margin: 5px; } } } } }

@media ( max-width: 900px ) {
    .landing {
        .content_section {
            .meantime_section {
                width: 100%; }

            .or_hr {
                display: block;
                text-align: center;
                width: 100%;
                padding-top: 20px;

                .or_divide {
                    border: none;
                    border-bottom: 1px solid black;
                    padding: 0px 80px;
                    height: 0px;

                    .or {
                        margin-top: -9px;
                        padding: 0px 4px;
                        margin-left: -11px; } } } } } }

@media ( max-width: 840px ) {
    .landing {

        .logo_wrap {
            text-align: center;
            margin: 0px 0px 0px 15px;
            padding-top: 15px;

            .logo {
                width: 90px; } }

        .heading {
            font-size: 40px;
            line-height: 42px;
            margin: 0px 0px 0px 15px;
            padding-top: 20px; }

        .mission {
            font-size: 40px;
            line-height: 42px;
            margin: 0px 0px 0px 15px;
 }            // padding-top: 15px

        .join {
            margin: 0px 0px 0px 15px;
            font-size: 24px; }

        .content_section {
            margin: 0px 0px 0px 15px; }

        .other_section {

            .tools, .videos {
                margin: 0px 0px 0px 15px; } } } }

@media ( max-width: 655px ) {
    .landing {
        .other_section {
            .videos {
                width: calc(100% - 15px);
                text-align: center;

                .heading {
                    text-align: left; } } } } }

@media ( min-height: 800px ) {
    .landing {
        .content_section {
            padding-top: 80px; } } }

@media ( min-height: 900px ) {
    .landing {
        .content_section {
            padding-top: 150px; } } }
