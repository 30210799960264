.accordion_button {
    display: inline-block;
    cursor: pointer; }

.accordion {
    overflow: hidden;
    transition: 0.2s all ease-in-out; }

.flow {
    .accordion_button {
        font-family: Universal;
        background-color: transparent;
        padding: 15px 21px;
        line-height: 30px;
        border: 1px solid black;
        outline: none;
        cursor: pointer;
        // width: calc(100% - 44px)
        // max-width: 680px

        &:hover {
            background-color: #AAFF00; } } }
