.minimal {
    width: 680px;
    max-width: calc(100% - 30px);
    padding: 15px;
    display: inline-block;

    .heading {
        font-size: 30px;
        line-height: 36px;
        margin: 40px 0px; }

    .content {
        font-size: 16px;
        margin-bottom: 20px; }

    .time_select, .placeholder {
        background-color: white;
        border: 1px solid black;
        width: 200px;
        display: inline-block;
        padding: 5px;
        text-align: left;
        outline: none;
        font-size: 18px; }

    .placeholder {
        width: calc(200px - 12px); }

    .selector_phrase {
        margin-bottom: 20px;

        .intro {
            display: inline-block;
            font-size: 18px;
            width: 70px;
            position: relative;
            text-align: left;

            .ending {
                display: inline-block;
                position: absolute;
                margin-left: 225px; } } }

    .lender_logo {
        width: 100px;
        border-radius: 50%;
        background-color: white; }

    .archive {
        background-color: #DDDDDD;
        padding: 15px;
        border-radius: 5px;
        margin: 20px 0px 0px 0px; }

    .lender_info {
        width: 300px;
        display: inline-block;
        margin: 10px 0px;

        .logo {
            display: inline-block;
            vertical-align: middle;
            width: 100px; }

        .text {
            display: inline-block;
            vertical-align: middle;
            width: 190px;
            text-align: left;
            padding-left: 10px;
            font-size: 12px;
            color: #8a8a8a; } } }
