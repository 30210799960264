body {
    font-size: 15px;
    line-height: 21px; }

#landing_page {
    background-color: #f0f0f0;

    .rosette {
        width: 180px;
        top: -280px; }

    .contentRosette {
        margin-top: 30px;
        margin-bottom: 30px; }

    li {
        text-align: left;
        background-image: url("./img/icon-tick-star.svg");
        background-repeat: no-repeat;
        padding-top: 7px;
        min-height: 45px;
        padding-left: 35px;
        padding-top: 3px;
        min-height: 36px;
        padding-left: 33px;
        background-size: 24px; }

    ul {
        list-style: none;
        padding-left: 20px;
        padding-right: 20px;
        max-width: 420px;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px; }

    .topBlock {
        width: 100%;
        background-color: black;
        color: white;
        font-family: Universal;
        font-size: 18px;
        overflow: hidden;
        font-size: 15px;
        line-height: 21px;

        .logo {
            width: 60px;
            margin: 15px 30px 30px;
            position: relative; }

        .topLink {
            position: absolute;
            top: 35px; }

        @media (max-width: 600px) {
            .topLink {
                display: none; } }

        .link2 {
            left: 400px; }

        .circleContainer {
            width: 100%;
            margin-top: 30px;
            margin-bottom: 108px; }

        .backgroundText {
            color: #333;
            font-family: Tiempos;
            font-size: 27px;
            line-height: 27px;
            width: 2932px;
            margin-left: -156px;
            /* margin-right: -50vw */
            position: relative;
            margin-top: -50px;
            height: 211px;
            overflow: hidden; }

        .rosetteText {
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.06em;
            color: black;
            position: relative;
            text-transform: uppercase;
            font-size: 18px;
            margin-top: -312px; }

        .wobbleCircle {
            position: relative;
            top: -197px; }

        button {
            font-size: 21px;
            padding: 15px 45px 15px 45px;
            border-radius: 45px;
            background-color: transparent;
            margin: 30px 0px 50px 0px;
            line-height: 21px;
            // letter-spacing: 0.06em
            outline: 0;
            cursor: pointer; }

        a {
            color: white; }

        .topHeader {
            padding-left: 20px;
            padding-right: 20px;

            .heading {
                font-size: 42px;
                line-height: 45px;
                font-family: Tiempos;
                padding-bottom: 20px; }

            .yellowBtn {
                border: 2px solid black;
                color: black;
                background-color: #AAFF00; } } }

    .whiteBox, .blackBox {
        padding: 50px;
        padding-left: 20px;
        padding-right: 20px;

        .heading {
            font-size: 45px;
            font-family: Tiempos;
            margin-bottom: 20px;
            line-height: 48px; }

        .subHeading {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 18px; }

        .content {
            font-size: 15px;
            line-height: 21px; }

        .linkDiv {
            margin-top: 20px; }

        .link {
            font-size: 15px;
            color: black;
            text-decoration: none;
            border-bottom: 1px solid black; } }

    .accordion {
        display: inline-table;
        max-width: 900px;
        width: 100%;
        border: 1px solid black;
        margin-left: -1px;
        margin-top: -1px;

        .accordionHeading {
            font-size: 18px;
            padding: 20px;
            padding-left: 30px;
            text-align: left;
            font-weight: 700;
            cursor: pointer;

            .accordionHeadingText {
                max-width: calc(100% - 40px);
                display: inline-block; } }

        .x {
            float: right;
            margin-top: -5px;
            transition: all 0.1s ease-in-out; }

        #sellingX, #mortgageX, #applyX, #insuranceX {
            transition: all 0.1s ease-in-out; }

        .accordionContent {
            text-align: left;
            font-size: 15px;
            line-height: 22px;
            margin-left: 30px;
            margin-right: 30px;
            max-height: 0px;
            transition: all 0.1s ease-in-out;
            overflow: hidden; }

        .accordionLink {
            text-decoration: none;
            color: black;
            border-bottom: 1px solid black;
            font-size: 15px; }

        .accordionLinkDiv {
            padding-top: 20px;
            padding-bottom: 30px; }

        .paddingBottom {
            height: 20px; } }

    .otherServices {
        .jobHeading {}

        .jobContent {} }

    .blackBox {
        background-color: black;
        color: white;
        padding: 1px;
        padding-left: 20px;
        padding-right: 20px;

        .quoteBox {
            padding: 20px;
            border: 1px solid white;
            border-radius: 10px;
            margin: 30px;
            margin-left: 0;
            margin-right: 0; }

        .quoteContent {
            font-family: Tiempos;
            font-size: 24px;
            line-height: 30px; }

        .quoteAuthor {
            padding-top: 10px;
            font-size: 18px; }

        .quoteResponse {
            font-family: Tiempos;
            font-size: 45px;
            margin-bottom: 20px;
            line-height: 48px; }

        .quoteResponseContent {
            font-size: 18px;
            margin-bottom: 20px;
            margin-top: 20px; }

        .explanationHeading {
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 18px; }

        button {
            font-size: 21px;
            padding: 15px 45px 15px 45px;
            border-radius: 45px;
            background-color: transparent;
            margin: 30px 0px 50px 0px;
            line-height: 21px;
            letter-spacing: 0.06em;
            outline: 0;
            cursor: pointer; }

        .yellowBtn {
            border: 2px solid black;
            color: black;
            background-color: #AAFF00; }

        .tvHeading {
            font-size: 12px;
            font-family: Universal;
            padding-top: 20px;
            padding-bottom: 20px;
            letter-spacing: 2px; }

        .tvIcon {
            margin-bottom: -10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 40px; }

        .video {
            width: 560px;
            height: 315px;
            max-width: 90vw;
            max-height: 50.625vw;
            border: none;
            margin-top: 15px; }

        .signature {
            margin-top: 30px;
            margin-bottom: 20px; }

        @media (min-width: 1126px) {
            .altTable {
                display: none; }

            .maxTable {
                margin-bottom: 30px; } }

        .link {
            color: white;
            border-bottom: 1px solid white; }

        @media (max-width: 1125px) {
            .video {
                margin-bottom: 30px; }

            .altTable {
                display: block;
                padding-bottom: 40px; }

            .maxTable {
                display: none; } }

        .socialIcon {
            height: 50px;
            margin: 15px;
            margin-top: 30px;
            margin-bottom: 30px; } }

    .bottomBox {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: -1px; }

    @media (min-width: 800px) {
        .whiteBox, .blackBox {
            padding-left: 10%;
            padding-right: 10%; }

        .quoteBox {
            margin-left: 30px;
            margin-right: 30px; }

        .quoteContent {
            font-size: 24px;
            line-height: 30px; } }

    .mention {
        padding-top: 30px;
        margin-bottom: -15px;
        font-size: 20px;
        font-weight: 500; }

    #homeX, #helpX, #marketX, #mortgageX, #applyX, #insuranceX {
        transition: all 0.1s ease-in-out; }

    @media (max-width: 680px) {
        .topBlock {
            .topHeader {
                .heading {
                    font-size: 27px;
                    line-height: 33px;
                    padding-bottom: 15px; } }

            button {
                font-size: 18px;
                padding: 12px 24px;
                margin: 21px 0 45px; } }

        .blackBox {

            button {
                font-size: 18px;
                padding: 12px 24px;
                margin: 21px 0 45px; }

            .quoteResponse {
                font-size: 30px;
                line-height: 36px; } }

        .whiteBox, .blackBox {
            padding: 36px 20px;

            .heading {
                font-size: 30px;
                line-height: 36px; } } }

    .menu-button {
        background: none;
        border: none;
        width: 52px;
        margin: 8px 0;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0px;
        right: 30px;
        display: none; }

    @media (max-width: 600px) {
        .menu-button {
            display: block; } } }
